import { tailwind } from "../../node_modules/@theme-ui/presets"

// var temp = {
//   transparent: 'transparent',
//   black: '#000',
//   white: '#fff',
//   gray: [null, '#f7fafc', '#edf2f7', '#e2e8f0', '#cbd5e0', '#a0aec0', '#718096', '#4a5568', '#2d3748', '#1a202c'],
//   red: [null, '#fff5f5', '#fed7d7', '#feb2b2', '#fc8181', '#f56565', '#e53e3e', '#c53030', '#9b2c2c', '#742a2a'],
//   orange: [null, '#fffaf0', '#feebc8', '#fbd38d', '#f6ad55', '#ed8936', '#dd6b20', '#c05621', '#9c4221', '#7b341e'],
//   yellow: [null, '#fffff0', '#fefcbf', '#faf089', '#f6e05e', '#ecc94b', '#d69e2e', '#b7791f', '#975a16', '#744210'],
//   green: [null, '#f0fff4', '#c6f6d5', '#9ae6b4', '#68d391', '#48bb78', '#38a169', '#2f855a', '#276749', '#22543d'],
//   teal: [null, '#e6fffa', '#b2f5ea', '#81e6d9', '#4fd1c5', '#38b2ac', '#319795', '#2c7a7b', '#285e61', '#234e52'],
//   blue: [null, '#ebf8ff', '#bee3f8', '#90cdf4', '#63b3ed', '#4299e1', '#3182ce', '#2b6cb0', '#2c5282', '#2a4365'],
//   indigo: [null, '#ebf4ff', '#c3dafe', '#a3bffa', '#7f9cf5', '#667eea', '#5a67d8', '#4c51bf', '#434190', '#3c366b'],
//   purple: [null, '#faf5ff', '#e9d8fd', '#d6bcfa', '#b794f4', '#9f7aea', '#805ad5', '#6b46c1', '#553c9a', '#44337a'],
//   pink: [null, '#fff5f7', '#fed7e2', '#fbb6ce', '#f687b3', '#ed64a6', '#d53f8c', '#b83280', '#97266d', '#702459']
// };

// console.log('tailwind:', tailwind)
const headingStyles = {
  h1: {
    ...tailwind.styles.h1,
    color: `heading`,
    fontSize: [5, 6, 7],
    mt: 2,
  },
  h2: {
    ...tailwind.styles.h2,
    color: `heading`,
    fontSize: [4, 5, 6],
    mt: 2,
  },
  h3: {
    ...tailwind.styles.h3,
    color: `heading`,
    fontSize: [3, 4, 5],
    mt: 3,
  },
  h4: {
    ...tailwind.styles.h4,
    color: `heading`,
    fontSize: [2, 3, 4],
  },
  h5: {
    ...tailwind.styles.h5,
    color: `heading`,
    fontSize: [1, 2, 3],
  },
  h6: {
    ...tailwind.styles.h6,
    color: `heading`,
    fontSize: 1,
    mb: 2,
  },
}

export default {
  ...tailwind,
  initialColorMode: `dark`,
  useCustomProperties: true,
  colors: {
    ...tailwind.colors,
    primary: tailwind.colors.orange[7],
    primaryHover: tailwind.colors.orange[5],
    secondary: tailwind.colors.gray[6], // `#5f6c80`
    secondaryHover: tailwind.colors.orange[6],
    tertiary: tailwind.colors.gray[6],
    tertiaryHover: tailwind.colors.orange[6],
    toggleIcon: tailwind.colors.gray[8],
    heading: tailwind.colors.black,
    divide: tailwind.colors.gray[4],
    modes: {
      dark: {
        text: tailwind.colors.gray[4],
        primary: tailwind.colors.orange[6],
        primaryHover: tailwind.colors.orange[4],
        secondary: tailwind.colors.gray[5], //`#7f8ea3`
        secondaryHover: tailwind.colors.gray[2],
        tertiary: tailwind.colors.gray[6],
        tertiaryHover: tailwind.colors.gray[2],
        toggleIcon: tailwind.colors.gray[4],
        background: `#1A202C`,
        heading: tailwind.colors.white,
        divide: tailwind.colors.gray[8],
        muted: tailwind.colors.gray[8],
      },
    },
  },
  fonts: {
    ...tailwind.fonts,
    body: `"IBM Plex Sans", -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
  },
  styles: {
    ...tailwind.styles,
    root: {
      ...tailwind.styles.root,
      color: `text`,
      backgroundColor: `background`,
    },
    a: {
      color: 'primary',
      textDecoration: 'none',
      ':hover': {
        color: `primaryHover`,
        textDecoration: 'none'
      },
      secondary: {
        color: `secondary`,
        textDecoration: `none`,
        ":hover": {
          color: `secondaryHover`,
          textDecoration: `none`,
        },
      }
    },
    p: {
      fontSize: [1, 1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
    },
    ul: {
      li: {
        fontSize: [1, 1, 2],
        letterSpacing: `-0.003em`,
        lineHeight: `body`,
        "--baseline-multiplier": 0.179,
        "--x-height-multiplier": 0.35,
      },
    },
    ol: {
      li: {
        fontSize: [1, 1, 2],
        letterSpacing: `-0.003em`,
        lineHeight: `body`,
        "--baseline-multiplier": 0.179,
        "--x-height-multiplier": 0.35,
      },
    },
    ...headingStyles,
    Container: {
      padding: [3, 4],
      // backgroundColor: `#303641`
    },
    blockquote: {
      borderLeftColor: `primary`,
      borderLeftStyle: `solid`,
      borderLeftWidth: `6px`,
      mx: 0,
      pl: 4,
      p: {
        fontStyle: `italic`,
      },
    },
    table: {
      width: `100%`,
      my: 4,
      borderCollapse: `separate`,
      borderSpacing: 0,
      [[`th`, `td`]]: {
        textAlign: `left`,
        py: `4px`,
        pr: `4px`,
        pl: 0,
        borderColor: `muted`,
        borderBottomStyle: `solid`,
      },
    },
    th: {
      verticalAlign: `bottom`,
      borderBottomWidth: `2px`,
      color: `heading`,
    },
    td: {
      verticalAlign: `top`,
      borderBottomWidth: `1px`,
    },
    
  },
  text: {
    ...headingStyles,
    heading: {
      fontFamily: `heading`,
      fontWeight: `heading`,
      lineHeight: `heading`,
      color: `heading`,
    },
  },
  dividers: {
    bottom: {
      borderBottomStyle: `solid`,
      borderBottomWidth: `1px`,
      borderBottomColor: `divide`,
      pb: 3,
    },
    top: {
      borderTopStyle: `solid`,
      borderTopWidth: `3px`,
      borderTopColor: `divide`,
      pt: 3,
    },
  },
  links: {
    secondary: {
      color: `secondary`,
      textDecoration: `none`,
      ":hover": {
        color: `secondaryHover`,
        textDecoration: `none`,
      },
      // ":focus": {
      //   color: `heading`,
      // },
    },
    tertiary: {
      color: `tertiary`,
      ":hover": {
        color: `tertiaryHover`,
        textDecoration: `none`,
      },
    },
    listItem: {
      fontSize: [1, 2, 3],
      color: `text`,
    },
    footerList: {
      fontSize: [1, 1, 2],
      color: `secondary`,
      textDecoration: `none`,
      ":hover": {
        color: `secondaryHover`,
        textDecoration: `none`,
      },
    },
  },
  buttons: {
    home: {
      fontSize: [1, 1, 2],
      position: 'absolute',
      top: ['50%', '50%'],
      left: ['3%', '25%'],
      transform: ['translate(0%, 0%)','translate(-65%, -20%)'],
      '-ms-transform': ['translate(0%, 0%)','translate(-50%, -50%)'],
      cursor: 'pointer',
      borderRadius: '5px',
      padding: ['6px 12px','9px 18px','12px 24px'],
    },
    home2: {
      fontSize: [1, 1, 1],
      position: 'absolute',
      top: '70%',
      left: '25%',
      transform: 'translate(-65%, -20%)',
      '-ms-transform': 'translate(-50%, -50%)',
      cursor: 'pointer',
      borderRadius: '5px',
      padding: '12px 24px',
      bg: 'transparent',
      // borderRadius: '5px',
      // border: '1px solid #333',
    }
  },
  alerts: {
    home: {
      fontSize: [2, 4, 5],
      textAlign: ['center','left'],
      position: 'absolute',
      top: ['5%','20%'],
      left: ['0%','25%'],
      transform: ['translate(0%, 0%)', 'translate(-55%, -20%)'],
      '-ms-transform': ['translate(0%, 0%)','translate(-50%, -50%)'],
      padding: '12px',
      bg: 'transparent'
    }
  },
  images: {
    oneThird: {
      // objectFit: 'contain',
      width: '50px',
      // width: ['30%', '50%', '50%']
    }
  }
}